






























































































import { Component, Vue } from 'vue-property-decorator'
import { RequestPaging } from '@/utils/util'
import {
    apiLuckyDrawLists,
    apiLuckyDrawDelete,
    apiLuckyDrawEnd,
    apiLuckyDrawStart
} from '@/api/marketing/lucky_draw'
import { PageMode } from '@/utils/type'
import LuckyDrawPane from '@/components/lucky-draw/lucky-draw-pane.vue'
import DatePicker from '@/components/date-picker.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LuckyDrawPane,
        DatePicker,
        ExportData
    }
})
export default class LuckyDraw extends Vue {
    apiLuckyDrawLists = apiLuckyDrawLists // 传递给导出组件的api

    // 分页查询
    pager: RequestPaging = new RequestPaging()

    form = {
        status: ' ', // 活动状态 0-未开始 1-进行中 2-已结束，默认：全部
        name: '',
        start_time: '',
        end_time: ''
    }

    extend = {
        all: 0,
        wait: 0,
        ing: 0,
        end: 0
    }

    // 重置
    onReset() {
        ;(this.form.status = ' '),
            (this.form.name = ''),
            (this.form.start_time = ''),
            (this.form.end_time = ''),
            this.getList()
    }

    // 获取列表
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiLuckyDrawLists,
                params: {
                    ...this.form
                }
            })
            .then((res: any) => {
                this.extend = res?.extend
            })
            .catch(() => {})
    }

    created() {
        this.getList()
    }
}





















































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import {
    apiLuckyDrawDelete,
    apiLuckyDrawEnd,
    apiLuckyDrawStart
} from '@/api/marketing/lucky_draw'
import { PageMode } from '@/utils/type'
@Component({
    components: {
        LsPagination,
        LsDialog
    }
})
export default class LuckyDrawPane extends Vue {
    @Prop() value: any // 列表数据
    @Prop() pager!: any // 含有分页信息的数据

    onDelete(value: any) {
        apiLuckyDrawDelete({
            id: value.id
        })
            .then(() => {
                this.$emit('refresh')
            })
            .catch((err: any) => {})
    }
    onStart(value: any) {
        apiLuckyDrawStart({
            id: value.id
        })
            .then(() => {
                this.$emit('refresh')
            })
            .catch((err: any) => {})
    }
    onStop(value: any) {
        apiLuckyDrawEnd({
            id: value.id
        })
            .then(() => {
                this.$emit('refresh')
            })
            .catch((err: any) => {})
    }

    // 添加
    goToADD() {
        this.$router.push({
            path: '/lucky_draw/edit',
            query: {
                mode: PageMode.ADD
            }
        })
    }

    goToEdit(val: any) {
        this.$router.push({
            path: '/lucky_draw/edit',
            query: {
                mode: PageMode.EDIT,
                status: val.status,
                id: val.id
            }
        })
    }

    goToDetails(val: any) {
        this.$router.push({
            path: '/lucky_draw/edit',
            query: {
                mode: PageMode.EDIT,
                status: val.status,
                id: val.id,
                type: 'details'
            }
        })
    }

    goTolog(val: any) {
        this.$router.push({
            path: '/lucky_draw/log',
            query: {
                id: val.id,
                create_time: val.create_time
            }
        })
    }
}
